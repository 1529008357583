import logo from "./Hamlyns Logo.svg";

export default function Holding() {
  return (
    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div>
        <a href="https://hamlyns.com">
          <span className="sr-only">Hamlyns</span>
          <img className="h-32 w-auto sm:h-16" src={logo} alt="Hamlyns Logo" />
        </a>
      </div>
      <div className="text-center">
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Page not available
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600">
          Sorry, you can only use this service if you have been sent a link.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <a
            href="mailto:info@hamlyns.com"
            className="text-sm font-semibold text-gray-900"
          >
            Contact us <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </div>
    </main>
  );
}
