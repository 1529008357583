import logo from "./Hamlyns Logo.svg";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

function App() {
  const params = useParams();

  const [companyName, setCompanyName] = useState("");

  useEffect(() => {
    axios
      .get("/glide/client/" + params["*"])
      .then((response) => {
        setCompanyName(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [params]);

  return (
    <div className="bg-white">
      <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
        <img className="mx-auto h-24 w-auto" src={logo} alt="Hamlyns" />
        <div className="mt-6 mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            2022/23 P11ds
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
            Please provide the information for {companyName} so we can complete
            your P11Ds for the tax year just passed. P11Ds are due with HMRC by
            6 July 2023, with any Class 1a NIC due by 19 July 2023.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href={"download/" + params["*"]}
              className="rounded-md bg-indigo-600 p-6 text-xl font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Download checklist
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
